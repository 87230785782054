import React from 'react';

function App() {
    return (
        <div>
            <div className="flex h-screen">
                <div className="m-auto">
                    <h1 className={"text-center text-8xl leading-loose"}>Tensorize</h1>
                </div>
            </div>
            {/*<Nav />*/}
            {/*<Hero />*/}
            {/*<WhatWeDo />*/}
            {/*<Callout />*/}
            {/*<Footer />*/}

        </div>
    );
}

export default App;
